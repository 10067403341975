import React, { useEffect, useState } from "react";

export default function ResendCodeTimer({ generateCode }) {
    const [minutes, setMinutes] = useState(2);
    const [seconds, setSeconds] = useState(59);

    useEffect(() => {
        const interval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }

            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(interval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    });

    const resendCode = () => {
        generateCode();
        setMinutes(2);
        setSeconds(59);
    };

    return (
        <div className="z-mt-5 z-no-mb z-apply-label-small resend-code-text">
            <p>
                {seconds > 0 || minutes > 0 ? (
                    <>
                        Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}{" "}
                        : {seconds < 10 ? `0${seconds}` : seconds}
                    </>
                ) : (
                    <>
                        Didn't recieve code?
                        <span
                            className="z-modal-parag-small"
                            onClick={() => resendCode()}
                        >
                            Click to resend
                        </span>
                    </>
                )}
            </p>
        </div>
    );
}
