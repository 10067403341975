import axios from "axios";
import {
    getCurrentUser,
    setCurrentUser,
} from "../reduxController/actions/userActions";
import {
    addBankURL,
    addCardToLoanUrl,
    addCompanyUrl,
    addIndustryUrl,
    addLoanPackageUrl,
    addNewAdminUrl,
    adminRequestResponseUrl,
    approveCompanyUrl,
    approveLoanPackageUrl,
    authorizeCardV2Url,
    bankURL,
    BASE_URL,
    cancelLoanRequestUrl,
    cashDownUrl,
    changeAdminRoleUrl,
    companyUpdateUrl,
    convertReferalPointsUrl,
    createBlacklistByUserIdUrl,
    createBlacklistUrl,
    createCompanyLevelUrl,
    createMandateUrl,
    deleteBlacklistUrl,
    deleteCompanyLevelUrl,
    deleteCompanyUrl,
    deleteLoanPackageUrl,
    deleteNotificationUrl,
    disburseLoanUrl,
    editCompanyLevelUrl,
    fundCashDownUrl,
    fundWalletUrl,
    generateLoanRequestAuthorizationCodeUrl,
    getAdminSettingsUrl,
    getAllblacklistUrl,
    getAllCompanyLevelsUrl,
    getAllCompanyUrl,
    getAllIndustryUrl,
    getAllPackagesUrl,
    getAllSystemTransactionUrl,
    getAllTransactionUrl,
    getAllUserUrl,
    getBanksUrl,
    getComanyLevelByCompanyIdUrl,
    getCompanyUrl,
    getDirectDebitBanksUrl,
    getDirectDebitNameEnquiryUrl,
    getGuarantorRequestsUrl,
    getLoanRequestsUrl,
    getLoanRequestUrl,
    getMandatesUrl,
    getMandateUrl,
    getNotificationsUrl,
    getReferalUrl,
    getUnreadNotificationsUrl,
    getUserCardByIdUrl,
    getUserCardsUrl,
    getUserTransactionUrl,
    getUserUrl,
    getUserWalletTransactionsURL,
    getWalletBalanceUrl,
    guarantorAcceptRequestUrl,
    guarantorBankDetailsUrl,
    guarantorDeclineRequestUrl,
    guarantorGuestAcceptRequestUrl,
    guarantorGuestDeclineRequestUrl,
    initializeCardUrl,
    initializeCardV2Url,
    initializeWalletTrnsactionUrl,
    loanPackageUpdateUrl,
    loanRepaymentSchedules,
    markAllNotificationUrl,
    markLoanScheduleAsPaidUrl,
    paybackSummaryUrl,
    payLoanScheduleUrl,
    redeemReferalUrl,
    registerUserUrl,
    requestLoanUrl,
    resendEmailVerificationLinkURL,
    resetPasswordLinkUrl,
    resolveAccountUrl,
    saveCardUrl,
    transferMoneyToBankUrl,
    transferWithdrawalUrl,
    updateAdminSettingsUrl,
    updateUserStatusUrl,
    updateUserUrl,
    uploadAvatarURL,
    uploadFileURL,
    userHasAccountUrl,
    UserLoginUrl,
    UserlogOutURL,
    verifyEmailUrl,
    verifyPasswordLinkUrl,
} from "./Urls";

const _axios = axios.create({
    responseType: "json",
});

export function setAuthorisationToken(token) {
    //setup interceptors for 401 errors
    _axios.interceptors.response.use(
        function (response) {
            //check the response status
            if (response.status === 401) {
                //clear the local storage
                localStorage.clear();
                //redirect to the login page
                window.location.href = "/";
            }
            // Do something with response data
            return response;
        },
        (error) => {
            //check the response status
            if (error.response && error.response.status === 401) {
                console.log("Interceptors");
                //clear the local storage
                localStorage.clear();
                //redirect to the login page
                window.location.href = "/";
            }
            // Do something with response error
            return Promise.reject(error);
        }
    );
    if (token) {
        //setting authorization header
        _axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        //axios.defaults.withCredentials = true;
    } else {
        delete _axios.defaults.headers.common["Authorization"];
    }
}
/**
 * Function to handle user login a user
 * */
export const userLogin = async (params) => {
    try {
        return await _axios.post(UserLoginUrl, params, {
            headers: { Accept: "application/json" },
        });
    } catch (err) {
        throw err.response;
    }
};

/**
 * Function to get
 * authenticated user data
 * */
export function getAuthUser() {
    return (dispatch) => {
        //dispatch the get user action
        dispatch(getCurrentUser());

        return _axios
            .get(getUserUrl)
            .then((res) => {
                //dispatch the user into the store
                dispatch(setCurrentUser({ ...res.data.data }));
            })
            .catch((err) => {
                //log out if error is 404
            });
    };
}

/**
 * Function to get
 * authenticated user data
 * */
export function getAuthUserCustom(token, callback) {
    return (dispatch) => {
        //dispatch the get user action
        dispatch(getCurrentUser());

        return _axios
            .get(getUserUrl, { headers: { Authorization: "Bearer " + token } })
            .then((res) => {
                //dispatch the user into the store
                localStorage.setItem("sfs_tk", token);
                //add token to request header
                setAuthorisationToken(token);
                //dispatch the auth user action

                localStorage.setItem("sfs_user", JSON.stringify(res.data.data));
                dispatch(setCurrentUser({ ...res.data.data }));
                callback(true, res.data.data);
            })
            .catch((err) => callback(false, err.response));
    };
}

/**
 * function to register
 * **/
export const registerUser = async (params) => {
    try {
        return await _axios.post(registerUserUrl, params);
    } catch (err) {
        throw err.response;
    }
};

export const UserlogOut = async () => {
    try {
        await _axios.post(UserlogOutURL);
    } catch (err) {
        throw err.response;
    }
};
/**
 * function to verify email
 * **/
export function verifyEmail(params, callback, token) {
    return _axios
        .post(verifyEmailUrl, params, {
            headers: { Authorization: "Bearer " + token },
        })
        .then((res) => callback(true, res.data.data))
        .catch((err) => callback(false, err.response));
}

/**
 * function to add industry
 * **/

export function addIndustry(params, callback) {
    return _axios
        .post(addIndustryUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}
/**
 * function to update user profile
 * **/
export const updateUser = async (params) => {
    try {
        return await _axios.post(updateUserUrl, params);
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to add company
 * **/

export function addCompany(params, callback) {
    return _axios
        .post(addCompanyUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * Pay Now
 */

export const payLoanSchedule = async (param, guarantor) => {
    try {
        return await _axios.post(payLoanScheduleUrl(param, guarantor));
    } catch (err) {
        console.log("Error making payment");
        throw err.response;
    }
};
/**
 * function to add loan package
 * **/

export function addLoanPackage(params, callback) {
    return _axios
        .post(addLoanPackageUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function for user to request loan
 * **/

export function requestLoan(params, callback) {
    return _axios
        .post(requestLoanUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to get all industries
 * **/
export function getIndustries(callback) {
    return _axios
        .get(getAllIndustryUrl)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to get all companies
 * **/
export const getCompanies = async () => {
    try {
        return await _axios.get(getAllCompanyUrl);
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to get all loan packages
 * **/
export function getPackages(callback) {
    return _axios
        .get(getAllPackagesUrl)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to get all user
 * **/
export const getUsers = async () => {
    try {
        return await _axios.get(getAllUserUrl);
    } catch (err) {
        console.log("Error getting users");
    }
};

/**
 * function to get user loan request
 * **/
export const getUserRequest = async () => {
    try {
        return await _axios.get(getLoanRequestsUrl);
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to get Guarantor loan request
 * **/
export const getGuarantorRequests = async () => {
    try {
        return await _axios.get(getGuarantorRequestsUrl);
    } catch (err) {
        console.log("Error getting guarantor requests");
    }
};

/**
 * function to get all loan requests
 * **/
export const getLoanRequest = async () => {
    try {
        return await _axios.get(getLoanRequestUrl);
    } catch (err) {
        console.log("Error getting loan request", err.response.status);
    }
};

/**
 * function to add bank details
 * **/

export function addBankDetails(params, callback) {
    return _axios
        .post(guarantorBankDetailsUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to accept guarantor request
 * **/

export function acceptGuarantorRequest(params, callback) {
    return _axios
        .post(guarantorAcceptRequestUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to decline guarantor request
 * **/
export function declineGuarantorRequest(params, callback) {
    return _axios
        .post(guarantorDeclineRequestUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to decline a guest guarantor (guarantor without an account Or has not logged in) request
 * **/
export function declineGuestGuarantorRequest(params, callback) {
    return _axios
        .post(guarantorGuestDeclineRequestUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to accept a guarantor (guarantor without an account Or has not logged in) request
 * **/
export function acceptGuestGuarantorRequest(params, callback) {
    return _axios
        .post(guarantorGuestAcceptRequestUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function for admin to accept or decline request
 * **/

export function adminResponseRequest(params, callback) {
    return _axios
        .post(adminRequestResponseUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to get user debit card
 * **/

export function getUserCards(callback) {
    return _axios
        .get(getUserCardsUrl)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

export function getUserCardById(id, callback) {
    return _axios
        .get(getUserCardByIdUrl(id))
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/*
 * function to save card details*/
export function saveCard(params, callback) {
    return _axios
        .post(saveCardUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/*
 * function to resolve account*/
export const resolveAccountCn = async (params) => {
    try {
        return await _axios.get(resolveAccountUrl(params));
    } catch (err) {
        throw err.response;
    }
};

/*
 * function to get bank names*/
export const getBankNames = async () => {
    try {
        return await _axios.get(getBanksUrl, {
            headers: { Authorization: "" },
        });
    } catch (err) {
        console.log("Error getting bank names");
    }
};

export const resendEmailVerificationLink = async (params) => {
    try {
        return await _axios.post(resendEmailVerificationLinkURL, params);
    } catch (error) {
        throw error.response;
    }
};

/*
 * function to add reset password*/
export const resetPasswordLink = async (params) => {
    try {
        return await _axios.post(resetPasswordLinkUrl, params);
    } catch (err) {
        throw err.response;
    }
};

/*
 * function to  reset and verify password*/
export function verifyPasswordLink(params, callback) {
    return _axios
        .post(verifyPasswordLinkUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/*
 * function to  approve company */
export function approveCompany(params, callback) {
    return _axios
        .post(approveCompanyUrl(params.id), params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/*
 * function to  add new admin */
export function addNewAdmin(params, callback) {
    return _axios
        .post(addNewAdminUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/*
 * function to  change admin role */
export function changeAdminRole(params, callback) {
    return _axios
        .post(changeAdminRoleUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/*
 * function to get transaction reference */
export function initializeCard(callback) {
    return _axios
        .post(initializeCardUrl)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/*
 * function to get wallet balance */
export const getWalletBalance = async () => {
    try {
        return await _axios.get(getWalletBalanceUrl);
    } catch (err) {
        console.log("Error getting wallet balance");
        throw err.response;
    }
};

/*
 * function to transfer money to bank */
export function transferMoneyToBank(param, callback) {
    return _axios
        .post(transferMoneyToBankUrl, param)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/*
 * function to transfer money to bank */
export function initializeWalletTransaction(param, callback) {
    return _axios
        .post(initializeWalletTrnsactionUrl, param)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/*
 * function to transfer money to bank */
export function fundWallet(param, callback) {
    return _axios
        .post(fundWalletUrl, param)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to accept guarantor request by cash down
 * **/

export function acceptGuarantorCashDownRequest(params, callback) {
    return _axios
        .post(cashDownUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to fund wallet for cash down
 * **/

export function fundCashDownRequest(params, callback) {
    return _axios
        .post(fundCashDownUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to fund wallet for cash down
 * **/

export const transferFundRequest = async (params) => {
    try {
        return await _axios.post(transferWithdrawalUrl, params);
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to get all notifications
 * **/

export const getNotifications = async () => {
    try {
        return await _axios.get(getNotificationsUrl);
    } catch (err) {
        console.error("Error getting notification");
        throw err.response;
    }
};

/**
 * function to get all notifications
 * **/

export const getUnreadNotification = async () => {
    try {
        return await _axios.get(getUnreadNotificationsUrl);
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to get all loan repayment progress
 * **/

export const getLoanRepaymentProgress = async () => {
    try {
        return await _axios.get(loanRepaymentSchedules);
    } catch (err) {
        throw err.response;
    }
};

export const getAllLoanSchedules = async (loanRequestId) => {
    try {
        console.log("Loan Request ID", loanRequestId);
        return await _axios.get(
            `${loanRepaymentSchedules}?loanRequest=${loanRequestId}`
        );
    } catch (err) {
        console.log("Error getting loan schedules");
        throw err.response;
    }
};
/**
 * function to disburse loan into user wallet
 * **/

export function disburseLoan(params, callback) {
    return _axios
        .post(disburseLoanUrl, params)
        .then((res) => callback(true, res.data.data))
        .catch((err) => {
            callback(false, err.response);
        });
}

/**
 * function to delete notification
 * **/

export const deleteNotification = async (params) => {
    try {
        return await _axios.delete(
            deleteNotificationUrl(params.notificationId)
        );
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to get all transactions
 * **/

export const getAllTransaction = async () => {
    try {
        return await _axios.get(getAllTransactionUrl);
    } catch (err) {
        console.log("Error getting all transactions");
        throw err.response;
    }
};

/**
 * function to get all system transactions
 * **/

export const getAllSystemTransaction = async () => {
    try {
        return await _axios.get(getAllSystemTransactionUrl);
    } catch (err) {
        console.log("Error getting system transactions");
        throw err.response;
    }
};

/**
 * function to get all user transactions for admin
 * **/
export const getUserTransactionsAdmin = async (params) => {
    try {
        return await _axios.post(getUserTransactionUrl, params);
    } catch (err) {
        console.log("Error getting user transactions");
        throw err.response;
    }
};

/**
 * function to get all states
 * **/

/**
 * function to get a state cities
 * **/

export function approveLoanPackage(params, callback) {
    return _axios
        .post(approveLoanPackageUrl(params.loanPackageId), params)
        .then((res) => callback(true, res.data))
        .catch((err) => {
            callback(false, err.response);
        });
}
/**
 * function mark notifications as read
 * **/

export const markAllNotification = async (params) => {
    try {
        return await _axios.post(markAllNotificationUrl, params);
    } catch (err) {
        throw err.response;
    }
};

/*update loan package*/

export const updateLoanPackage = async (params) => {
    try {
        return await _axios.post(
            loanPackageUpdateUrl(params.get("loanPackageId")),
            params
        );
    } catch (err) {
        throw err.response;
    }
};

/*update company*/
export const editCompany = async (id, params) => {
    try {
        return await _axios.post(companyUpdateUrl(id), params);
    } catch (err) {
        throw err.response;
    }
};

/** Wallet Transactions **/
export const getWalletTransactions = async () => {
    try {
        return await _axios.get(getUserWalletTransactionsURL);
    } catch (err) {
        throw err.response;
    }
};

/** Add New Bank*/
export const addNewBank = async (params) => {
    try {
        return await _axios.post(bankURL, params);
    } catch (err) {
        throw err.response;
    }
};

export const getUsersBanks = async () => {
    try {
        return await _axios.get(bankURL);
    } catch (err) {
        throw err.response;
    }
};

export const uploadFile = async (params) => {
    try {
        return await _axios.post(uploadFileURL, params, {
            headers: { "Content-Type": "multipart/form-data" },
        });
    } catch (err) {
        throw err.response;
    }
};

export const updateAvatar = async (params) => {
    try {
        return await _axios.post(uploadAvatarURL, params);
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to get all blacklisted emails
 * **/
export const getAllBlacklist = async () => {
    try {
        return await _axios.get(getAllblacklistUrl);
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to blacklist emails
 * **/
export const createBlacklist = async (params) => {
    try {
        return await _axios.post(createBlacklistUrl, params);
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to blacklist emails by user Id
 * **/
export const createBlacklistByUserId = async (userId) => {
    try {
        return await _axios.post(createBlacklistByUserIdUrl(userId));
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to remove blacklist
 * **/
export const deleteBlacklist = async (blacklistId) => {
    try {
        return await _axios.delete(deleteBlacklistUrl(blacklistId));
    } catch (err) {
        throw err.response;
    }
};

/**
 * get company levels by company Id
 * returns all the levels of a particular company
 */
export const getComanyLevelByCompanyId = async (companyId) => {
    try {
        return await _axios.get(getComanyLevelByCompanyIdUrl(companyId));
    } catch (err) {
        throw err.response;
    }
};

/**
 * creates a company level
 */
export const createCompanyLevel = async (params) => {
    try {
        return await _axios.post(createCompanyLevelUrl, params);
    } catch (err) {
        throw err.response;
    }
};

/**
 * edits a company level
 * @param {number} companyLevelId Company level Id
 * @param {object} params params to be updated
 */
export const editCompanyLevel = async (companyLevelId, params) => {
    try {
        return await _axios.put(editCompanyLevelUrl(companyLevelId), params);
    } catch (err) {
        throw err.response;
    }
};

/**
 * deletes a company level
 * @param {number} companyLevelId Company level Id
 */
export const deleteCompanyLevel = async (companyLevelId) => {
    try {
        return await _axios.delete(deleteCompanyLevelUrl(companyLevelId));
    } catch (err) {
        throw err.response;
    }
};

/**
 * Fetch a company from server
 * @param {number} companyId Company Id of the company to fetch
 */
export const getCompany = async (companyId) => {
    try {
        return await _axios.get(getCompanyUrl(companyId));
    } catch (err) {
        throw err.response;
    }
};

/**
 * function to get all company levels
 * **/
export function getAllCompanyLevels() {
    return _axios.get(getAllCompanyLevelsUrl);
}

/**
 * cancel a Loan Request
 * @param {number} loanRequestId Loan Request Id
 */
export const cancelLoanRequest = async (loanRequestId) => {
    try {
        return await _axios.post(cancelLoanRequestUrl, {
            loanRequestId,
        });
    } catch (err) {
        throw err.response;
    }
};

/**
 * deletes a company
 * @param {number} companyId Company level Id
 */
export const deleteCompany = async (companyLevelId) => {
    try {
        return await _axios.delete(deleteCompanyUrl(companyLevelId));
    } catch (err) {
        throw err.response;
    }
};

/**
 * deletes/disapprove a loan package
 * @param {number} companyId Company level Id
 */
export const deleteLoanPackage = async (companyLevelId) => {
    try {
        return await _axios.delete(deleteLoanPackageUrl(companyLevelId));
    } catch (err) {
        throw err.response;
    }
};

/**
 * Update user account status
 * @param {number} userId
 * @param {boolean} status
 */
export const updateUserStatus = async (userId, status) => {
    try {
        return await _axios.post(updateUserStatusUrl(userId), {
            status,
        });
    } catch (err) {
        throw err.response;
    }
};

/**
 * Fetches payback summary information for a loan request.
 *
 * @async
 * @function
 * @param {string} loanRequestId - The ID of the loan request for which payback summary is requested.
 * @param {number} amountRequested - The amount requested in the loan.
 * @returns {Promise} - A promise that resolves to the payback summary information fetched from the server.
 * @throws {Object} - The error response in case of an error during the API request.
 */
export const getPaybackSummary = async (loanRequestId, amountRequested) => {
    try {
        return await _axios.get(paybackSummaryUrl, {
            params: { loan_package_id: loanRequestId, amount: amountRequested },
        });
    } catch (err) {
        throw err.response;
    }
};

export const getReferal = async () => {
    try {
        return await _axios.get(getReferalUrl);
    } catch (err) {
        throw err.response;
    }
};

export const redeemReferal = async () => {
    try {
        return await _axios.post(redeemReferalUrl);
    } catch (err) {
        throw err.response;
    }
};

export const convertReferalPoints = async () => {
    try {
        return await _axios.post(convertReferalPointsUrl);
    } catch (err) {
        throw err.response;
    }
};

export const getAdminSettings = async () => {
    try {
        return await _axios.get(getAdminSettingsUrl);
    } catch (err) {
        throw err.response;
    }
};

export const updateAdminSetting = async (data) => {
    try {
        return await _axios.post(updateAdminSettingsUrl, data);
    } catch (err) {
        throw err.response;
    }
};

export const markLoanScheduleAsPaid = async (loanRequestId) => {
    try {
        return await _axios.post(markLoanScheduleAsPaidUrl(loanRequestId));
    } catch (err) {
        throw err.response;
    }
};

export const generateLoanRequestAuthorizationCode = async () => {
    try {
        return await _axios.post(generateLoanRequestAuthorizationCodeUrl);
    } catch (err) {
        throw err.response;
    }
};

export const userHasAccount = async (email) => {
    try {
        return await _axios.get(userHasAccountUrl, {
            params: {
                email,
            },
        });
    } catch (err) {
        throw err.response;
    }
};

/*
 * function to initailize new card payment */
export async function initializeNewCardV2(callback_url) {
    try {
        return await _axios.post(initializeCardV2Url, {
            callback_url,
        });
    } catch (err) {
        throw err.response;
    }
}

/*
 * function to authorize a card by reference */
export async function authorizeNewCardV2(reference) {
    try {
        return await _axios.post(authorizeCardV2Url, {
            reference,
        });
    } catch (err) {
        throw err.response;
    }
}

/*
 * function to get banks for direct debit */
export async function getDirectDebitBanks() {
    try {
        return await _axios.get(getDirectDebitBanksUrl);
    } catch (err) {
        throw err.response;
    }
}

/*
 * function to get account name for direct debit */
export async function getDirectDebitNameEnquiry(data) {
    try {
        return await _axios.post(getDirectDebitNameEnquiryUrl, data);
    } catch (err) {
        throw err.response;
    }
}

/*
 * function to create mandate for direct debit */
export async function createMandate(data) {
    try {
        return await _axios.post(createMandateUrl, data);
    } catch (err) {
        throw err.response;
    }
}

/*
 * function to get mandates for direct debit */
export async function getMandates() {
    try {
        return await _axios.get(getMandatesUrl);
    } catch (err) {
        throw err.response;
    }
}

/*
 * function to get mandate for direct debit */
export async function getMandate(id) {
    try {
        return await _axios.get(getMandateUrl(id));
    } catch (err) {
        throw err.response;
    }
}