import React from "react";
import { REPAYMENT_OPTIONS } from "../../../constants";
import { Checkbox, Col, Row, Input } from "antd";
import ResendCodeTimer from "./ResendCodeTimer";

export default function RepaymentAcknowledgement({
    isAcknowleged,
    setIsAcknowledged,
    repaymentOption,
    selectedCard,
    handleChange,
    authorizationCode,
    generateCode
}) {
    return (
        <>
            <Row className="z-mt-7">
                <Col span={12} className="z-width-44 z-mr-6">
                    {repaymentOption === REPAYMENT_OPTIONS.CARD && (
                        <>
                            <p className="z-apply-label-small">Selected card</p>
                            <div className="z-footer-input z-apply-input z-apply-input-small z-lowercase">
                                {selectedCard &&
                                    `**** **** **** ${selectedCard}`}
                            </div>
                        </>
                    )}
                </Col>
                <Col span={12} className="z-width-44 z-mr-6">
                    <p className="z-apply-label-small">Authorization Code</p>
                    <Input
                        size="large"
                        placeholder="*** ***"
                        name="authorizationCode"
                        value={authorizationCode}
                        onChange={(e) => handleChange(e)}
                        className="z-footer-input z-apply-input z-apply-input-small"
                    />
                    <ResendCodeTimer generateCode={generateCode} />
                </Col>
            </Row>
            <Row>
                <Col span={12} className="z-width-44 z-mr-6">
                    <div className="z-apply-label-large z-label-custom">
                        <Checkbox
                            className="z-no-mb z-modal-parag-small z-mt-5"
                            onChange={() => setIsAcknowledged(!isAcknowleged)}
                            checked={isAcknowleged}
                            name="acknowlege"
                        >
                            {repaymentOption === REPAYMENT_OPTIONS.CARD
                                ? `I understand and confirm that the debit card added for
                        the loan repayment is the same card linked to my salary
                        account`
                                : `I understand and confirm that the bank account for
                        the loan repayment is the same as my salary
                        account`}
                        </Checkbox>
                    </div>
                </Col>
            </Row>
        </>
    );
}
