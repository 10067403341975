import React, { useState } from "react";
import {
    message,
    Row,
    Col,
    Select,
    Skeleton,
    Input,
    Spin,
    Button,
    Modal,
    Radio,
    Popconfirm,
} from "antd";
import { useEffect } from "react";
import {
    createMandate,
    getDirectDebitBanks,
    getDirectDebitNameEnquiry,
    getMandates,
} from "../../../APIsHandler/ApiController";
import { useHistory } from "react-router-dom";

const { Option } = Select;

export default function Bank({ hideProceedBtn = false }) {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [banks, setBanks] = useState([]);
    const [mandates, setMandates] = useState([]);
    const [mandateId, setMandateId] = useState(null);
    const [showCreateMandate, setShowCreateMandate] = useState(false);

    useEffect(() => {
        setLoading(true);
        getMandates()
            .then(({ data }) => {
                setLoading(false);
                setMandates(data.data);
                if (data.data.length > 0) {
                    setMandateId(data.data[0].id);
                } else {
                    setShowCreateMandate(true);
                }
            })
            .catch(() => {
                setLoading(false);
                message.error("Unable to retrieve accounts at the moment.", 3);
            });

        getDirectDebitBanks()
            .then(({ data }) => {
                setBanks(data.data);
            })
            .catch((err) => {
                message.error("Unable to retrieve banks at the moment.", 3);
            });
    }, []);

    function handleProceed() {
        const param = new URLSearchParams({
            repayment_option: "bank",
            id: mandateId,
        });

        history.push(`/dashboard/apply/?${param.toString()}`);
    }

    return (
        <Skeleton active loading={loading}>
            {!showCreateMandate ? (
                <>
                    <Mandate
                        banks={banks}
                        mandates={mandates}
                        mandateId={mandateId}
                        setMandateId={setMandateId}
                    />
                    {mandates.length > 0 && (
                        <div
                            className="z-flex-center"
                            onClick={() => setShowCreateMandate(true)}
                        >
                            <p className="z-apply-label-small z-no-mb z-modal-parag-small">
                                Add new bank
                            </p>
                        </div>
                    )}
                    {!hideProceedBtn && <div className="card-btn-group">
                        <Popconfirm
                            title="Do you want to continue with this bank?"
                            onConfirm={handleProceed}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button
                                className="z-landing-btn z-section-btn z-profile-button z-loan-btn"
                                shape="round"
                                size="large"
                                key="1"
                            >
                                Proceed{" "}
                            </Button>
                        </Popconfirm>
                    </div>}
                </>
            ) : (
                <NewMandate banks={banks} hideProceedBtn={hideProceedBtn} />
            )}
        </Skeleton>
    );
}

function Mandate({ mandates, mandateId, setMandateId }) {
    return (
        <Row>
            <Col span={24}>
                <Radio.Group
                    value={mandateId}
                    className="z-mandate-radio-group"
                    onChange={(e) => setMandateId(e.target.value)}
                >
                    {mandates.map(
                        ({ id, account_name, account_number, bank_name }) => {
                            return (
                                <div key={id}>
                                    <Radio.Button
                                        className="z-mandate-radio-button"
                                        value={id}
                                    >
                                        <b>{bank_name}</b>
                                        <p>{account_number}</p>
                                        <p className="z-mandate-account-name">
                                            {account_name}
                                        </p>
                                    </Radio.Button>
                                    <br />
                                </div>
                            );
                        }
                    )}
                </Radio.Group>
            </Col>
        </Row>
    );
}

function NewMandate({ banks, hideProceedBtn }) {
    const [bankCode, setBankCode] = useState("");
    const [bankNipCode, setBankNipCode] = useState(null);
    const [accountNumber, setAccountNumber] = useState("");
    const [accountName, setAccountName] = useState("");
    const [bankName, setBankName] = useState("");
    const [accountNameLoading, setAccountNameLoading] = useState(false);
    const [createDirectDebitLoading, setCreateDirectDebitLoading] =
        useState(false);
    const [showModal, setShowModal] = useState(false);
    const [createDirectDebitResponse, setCreateDirectDebitResponse] =
        useState(null);

    useEffect(() => {
        // handles account name
        if (!bankCode || accountNumber.length < 10) return;

        setAccountNameLoading(true);

        getDirectDebitNameEnquiry({
            account_number: accountNumber,
            bank_code: bankNipCode,
        })
            .then(({ data }) => {
                setAccountNameLoading(false);
                setAccountName(data.data.accountName);
            })
            .catch(() => {
                setAccountNameLoading(false);
                message.error(
                    "Unable to retrieve account name at the moment.",
                    3
                );
            });
    }, [accountNumber, bankCode]);

    function handleChangeBank(value) {
        setBankCode(value);

        const bank = banks.find((bank) => bank.cbnBankCode === value)
        if(bank) {
            setBankName(bank.bankName)
            setBankNipCode(bank.nipCode)
        }
    }

    function handleCreateDirectDebit() {
        setCreateDirectDebitLoading(true);
        const payload = {
            account_number: accountNumber,
            bank_code: bankCode,
            account_name: accountName,
            bank_name: bankName,
        };

        createMandate(payload)
            .then(({ data }) => {
                openModal();
                setCreateDirectDebitLoading(false);
                setCreateDirectDebitResponse(data.data);
                message.success("Mandate created successfully.", 3);
            })
            .catch((err) => {
                setCreateDirectDebitLoading(false);
                message.error("Unable to create Mandate at the moment.", 3);
            });
    }

    function openModal() {
        setShowModal(true);
    }

    function closeModal() {
        setShowModal(false);
    }

    function isProceedButtonDisabled() {
        if (
            accountNumber >= 10 &&
            bankCode.length > 0 &&
            accountName.length > 0
        ) {
            return false;
        }

        return true;
    }

    return (
        <Spin spinning={createDirectDebitLoading} size="large">
            <Row>
                <Col span={12} className="z-width-44 z-mr-6">
                    <p className="z-apply-label-small">Select Bank</p>
                    <Select
                        className="z-apply-select"
                        showSearch
                        style={{ width: "100%" }}
                        optionFilterProp="children"
                        placeholder="Select a bank"
                        onChange={handleChangeBank}
                        value={bankCode}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }
                    >
                        {banks &&
                            banks.map((bank) => (
                                <Option
                                    value={bank.cbnBankCode}
                                    key={bank.cbnBankCode}
                                >
                                    {bank.bankName}
                                </Option>
                            ))}
                    </Select>
                </Col>
                <Col span={12} className="z-width-44">
                    <p className="z-apply-label-small">Account Number</p>
                    <div>
                        <Input
                            size="large"
                            placeholder="Account Number"
                            name="acountNumber"
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                            className="z-footer-input z-apply-input z-apply-input-small z-lowercase"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="z-mt-7">
                <Col span={12} className="z-width-44">
                    <p className="z-apply-label-small">Account Name</p>
                    <Skeleton loading={accountNameLoading} active>
                        <div>
                            <Input
                                size="large"
                                placeholder="Account Name"
                                name="accountName"
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                                className="z-footer-input z-apply-input z-apply-input-small"
                                disabled={accountName.length > 2}
                            />
                        </div>
                    </Skeleton>
                </Col>
            </Row>
            <Row className="z-mt-15_">
                <Col span={24} className="z-flex-center">
                    <Button
                        className="z-landing-btn z-section-btn z-profile-button z-loan-btn"
                        shape="round"
                        size="large"
                        onClick={handleCreateDirectDebit}
                        disabled={isProceedButtonDisabled()}
                    >
                        Proceed
                    </Button>
                </Col>
            </Row>
            <AuthorizationInfomation
                showModal={showModal}
                closeModal={closeModal}
                createDirectDebitResponse={createDirectDebitResponse}
                hideProceedBtn={hideProceedBtn}
            />
        </Spin>
    );
}

function AuthorizationInfomation({
    showModal,
    closeModal,
    createDirectDebitResponse,
    hideProceedBtn,
}) {
    const history = useHistory();

    function handleCompleteAuthorization() {
        closeModal();
        const param = new URLSearchParams({
            repayment_option: "bank",
            id: createDirectDebitResponse.id,
        });

        message.success(
            "Bank added successfully",
            3
        );

        hideProceedBtn
            ? history.go(0)
            : history.push(`/dashboard/apply/?${param.toString()}`);
    }

    return (
        <Modal
            className="z-custom z-custom2 z-custom3 z-app-modal"
            visible={showModal}
            title={"_"}
            onCancel={closeModal}
            closable={false}
            maskClosable={false}
            footer={[
                <Button
                    className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row z-btn-company z-border-active"
                    shape="round"
                    size="large"
                    onClick={handleCompleteAuthorization}
                    key={1}
                >
                    Done
                </Button>,
            ]}
        >
            <div>
                <div className="z-flex-space z-modal-label-div">
                    <div>
                        <p className="z-loan-header">
                            Authorization Information
                        </p>
                    </div>
                </div>

                <Row className="z-flex-space">
                    <Col span={24} className="z-mandate-response">
                        {createDirectDebitResponse &&
                            createDirectDebitResponse.responseDescription}
                    </Col>
                </Row>
            </div>
        </Modal>
    );
}
