import React, { Component } from "react";
import {
    Button,
    Col,
    Icon,
    Menu,
    message,
    Modal,
    Popconfirm,
    Row,
    Skeleton,
    Table,
} from "antd";
import DefaultAvatar from "../../../assets/images/unisex_avatar_icon.png";
import Column from "antd/lib/table/Column";
import {
    adminResponseRequest,
    disburseLoan,
    getLoanRequest,
} from "../../../APIsHandler/ApiController";
import moment from "moment";
import { formatCurrency, naira } from "../../globalComponents/globalFunctions";
import NavigationTab from "../../NavigationTab";
import { Redirect } from "react-router-dom";
import { REPAYMENT_OPTIONS } from "../../../constants";

export default class LoanRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            collapsed: false,
            todayDate: new Date(),
            selectedRowKeys: [],
            loading: true,
            usersLoans: [],
            viewDetails: false,
            currentIndex: 0,
            declineRequest: false,
            addRequest: false,
            currentNavIndex: 0,
            nextNavIndex: 10,
            navDiff: 10,
            noOfPages: 0,
            currentPage: 1,
            disburse: false,
            currentUser: "user",
            redirectToLoanSchedules: false,
            currentLoanRequest: "",
        };
    }

    componentDidMount() {
        getLoanRequest()
            .then((res) => {
                let { data } = res.data;
                let num = Math.ceil(data.length / this.state.navDiff);
                this.setState({
                    usersLoans: data,
                    noOfPages: num,
                    loading: false,
                });
            })
            .catch((err) => {
                message.error("Unable to load page", 3);
                this.setState({ loading: false });
            });
    }

    onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    onSelectChange = (selectedRowKeys) => {
        console.log("selectedRowKeys changed: ", selectedRowKeys);
        this.setState({ selectedRowKeys });
    };
    //closes details modal
    closeModal = (e) => {
        this.setState({ addDetails: false, viewDetails: false });
    };

    //shows details modal
    showModal = (e, index) => {
        this.setState({ currentIndex: index, viewDetails: true });
    };

    cancel = () => console.log("cancelled");

    displayPaybackSchedule = (event, loanRequestId) => {
        event.preventDefault();
        this.setState({
            redirectToLoanSchedules: true,
            currentLoanRequest: loanRequestId,
        });
    };

    /*
     * Accept request*/
    acceptRequest = () => {
        const { usersLoans, currentIndex } = this.state;

        this.setState({ addRequest: true });
        adminResponseRequest(
            {
                loan_request_id: usersLoans[currentIndex].id,
                status: "approved",
                comment: "Approved",
            },
            this.onAddDatails
        );
    };

    onAddDatails = (status, data) => {
        if (status) {
            this.setState({ addRequest: false });
            message.success("The request has been accepted!");
            window.location.reload();
        } else {
            console.log(data);
            message.error("Unable to accept request! " + data.data.message);
            this.setState({ addRequest: false });
        }
    };

    /*
     * Accept request*/
    declineLoan = () => {
        const { usersLoans, currentIndex } = this.state;

        this.setState({ declineRequest: true });
        adminResponseRequest(
            {
                loan_request_id: usersLoans[currentIndex].id,
                status: "declined",
                comment: "Declined",
            },
            this.onDeclineDatails
        );
    };

    onDeclineDatails = (status, data) => {
        if (status) {
            console.log(data);
            this.setState({ declineRequest: false });
            message.success("The request has been declined!");
            window.location.reload();
        } else {
            console.log(data);
            message.error("Unable to decline request! " + data.data.message);
            this.setState({ declineRequest: false });
        }
    };

    navigateRight = () => {
        const {
            navDiff,
            nextNavIndex,
            currentNavIndex,
            currentPage,
            noOfPages,
        } = this.state;

        if (currentPage !== noOfPages) {
            this.setState({
                nextNavIndex: nextNavIndex + navDiff,
                currentNavIndex: currentNavIndex + navDiff,
                currentPage: (nextNavIndex + navDiff) / navDiff,
            });
        }
    };

    navigateLeft = () => {
        const { navDiff, nextNavIndex, currentNavIndex, currentPage } =
            this.state;

        if (currentPage !== 1) {
            this.setState({
                nextNavIndex: nextNavIndex - navDiff,
                currentNavIndex: currentNavIndex - navDiff,
                currentPage: (nextNavIndex - navDiff) / navDiff,
            });
        }
    };

    /*disburse loan into user wallet*/

    transferFund = () => {
        const { usersLoans, currentIndex } = this.state;
        this.setState({ disburse: true });

        const params = {
            loan_request_id: usersLoans[currentIndex].id,
            status: "success",
            type: "credit",
            description: `Credit wallet for ${
                usersLoans[currentIndex].loan_package.name
            } loan of ${naira} ${formatCurrency(
                usersLoans[currentIndex].loan_package.amount
            )}.`,
        };
        disburseLoan(params, this.onDisburse);
    };

    onDisburse = (status, data) => {
        if (status) {
            this.setState({ disburse: false });
            message.success("Money has been disbursed into user wallet!", 5);
            window.location.reload();
        } else {
            message.error("Unable to disburse fund.");
        }
    };

    //switch between user and guarantor
    setUser = (name) => {
        this.setState({ currentUser: name });
    };

    guarantorProfilePicture = (usersLoans, currentIndex) => {
        return usersLoans.length > 0
            ? usersLoans[currentIndex].request_guarantor
                ? usersLoans[currentIndex].request_guarantor.user
                    ? usersLoans[currentIndex].request_guarantor.user
                          .user_profile
                        ? usersLoans[currentIndex].request_guarantor.user
                              .user_profile.avatar
                        : DefaultAvatar
                    : DefaultAvatar
                : DefaultAvatar
            : DefaultAvatar;
    };

    getMandateStatus = (usersLoans, currentIndex) => {
        return (
            usersLoans.length > 0 &&
            usersLoans[currentIndex] &&
            usersLoans[currentIndex].mandate &&
            usersLoans[currentIndex].mandate.status
        );
    };

    render() {
        const {
            selectedRowKeys,
            usersLoans,
            loading,
            viewDetails,
            currentIndex,
            declineRequest,
            addRequest,
            currentNavIndex,
            nextNavIndex,
            navDiff,
            currentPage,
            disburse,
            currentUser,
            currentLoanRequest,
            redirectToLoanSchedules,
        } = this.state;
        console.log("state", this.state);

        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        if (redirectToLoanSchedules) {
            return (
                <Redirect
                    to={`/dashboard/admin/loan-schedules/${currentLoanRequest}`}
                />
            );
        }
        return (
            <div>
                <div>
                    <h1 className="z-dashboard-label">Loan Requests</h1>
                </div>
                <Skeleton loading={loading} active>
                    <div>
                        <Row>
                            <Col span={24}>
                                <div
                                    className="z-dashboard-panel z-mb-5 z-width-100 z-admin-panel2"
                                    style={{ height: "100%" }}
                                >
                                    <div className="z-flex-start z-mb-6">
                                        <Icon
                                            type="copy"
                                            className="z-label-icon"
                                        />
                                        <p className="z-label-text">
                                            Loan Requests
                                        </p>
                                    </div>
                                    <div>
                                        <Table
                                            rowSelection={rowSelection}
                                            bordered={false}
                                            dataSource={usersLoans.slice(
                                                currentNavIndex,
                                                nextNavIndex
                                            )}
                                            className="z-admin-table z-todo"
                                            pagination={false}
                                            rowKey="id"
                                            rowClassName={(record, index) => {
                                                if (index % 2 === 0) {
                                                    return "z-stripped";
                                                }
                                            }}
                                            scroll={{ x: "220%" }}
                                        >
                                            <Column
                                                title="Mark all"
                                                dataIndex="sn"
                                                key="sn"
                                                render={(
                                                    text,
                                                    record,
                                                    index
                                                ) => {
                                                    return (
                                                        <div className="">
                                                            <p className="z-table-num z-no-mb">
                                                                {Number(
                                                                    index +
                                                                        (currentPage -
                                                                            1) *
                                                                            navDiff
                                                                ) + 1}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Loan Request ID"
                                                dataIndex="loan_id"
                                                key="loan_id"
                                                render={(
                                                    text,
                                                    record,
                                                    index
                                                ) => {
                                                    return (
                                                        <div className="">
                                                            <p className="z-table-num z-no-mb">
                                                                {record.id}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Name"
                                                dataIndex="name"
                                                key="name"
                                                render={(text, record) => {
                                                    return (
                                                        <div className="z-flex-space">
                                                            <div className="z-more">
                                                                <Icon
                                                                    type="more"
                                                                    className="z-font-12"
                                                                />
                                                            </div>
                                                            <div className="z-unisex-avatar z-table-avatar">
                                                                <img
                                                                    src={
                                                                        record
                                                                            .user
                                                                            .user_profile
                                                                            ? record
                                                                                  .user
                                                                                  .user_profile
                                                                                  .avatar
                                                                                ? record
                                                                                      .user
                                                                                      .user_profile
                                                                                      .avatar
                                                                                : DefaultAvatar
                                                                            : DefaultAvatar
                                                                    }
                                                                    alt="logo"
                                                                />
                                                            </div>
                                                            <div>
                                                                <p className="z-table-name z-table-parag">
                                                                    {
                                                                        record
                                                                            .user
                                                                            .full_name
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Status"
                                                dataIndex="status"
                                                key="status"
                                                render={(text, record) => {
                                                    return (
                                                        <div>
                                                            <p
                                                                className={
                                                                    record.status ===
                                                                    "approved"
                                                                        ? "z-table-status z-table-parag2 z-color-green2"
                                                                        : record.status ===
                                                                          "pending"
                                                                        ? "z-table-status z-table-parag2 z-color-yellow"
                                                                        : "z-table-status z-table-parag2"
                                                                }
                                                            >
                                                                {record.status}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Loan Disbursed"
                                                dataIndex="loan_disbursed"
                                                key="loan_disbursed"
                                                render={(text, record) => {
                                                    return (
                                                        <div>
                                                            <p
                                                                className={
                                                                    record.loan_disbursed ===
                                                                    1
                                                                        ? "z-table-status z-table-parag2 z-color-green2"
                                                                        : record.loan_disbursed ===
                                                                          0
                                                                        ? "z-table-status z-table-parag2 z-color-yellow"
                                                                        : "z-table-status z-table-parag2"
                                                                }
                                                            >
                                                                {record.loan_disbursed ===
                                                                1
                                                                    ? "disbursed"
                                                                    : "not yet disbursed"}
                                                            </p>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Guarantor"
                                                dataIndex="guarantor"
                                                key="guarantor"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag">
                                                            {record.request_guarantor
                                                                ? record
                                                                      .request_guarantor
                                                                      .guarantor_email
                                                                : "null"}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Guarantor Approval Status"
                                                dataIndex="guarantor_approval_status"
                                                key="guarantor_approval_status"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag">
                                                            {record.request_guarantor
                                                                ? record
                                                                      .request_guarantor
                                                                      .guarantor_approved
                                                                : "null"}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Type of Funding"
                                                dataIndex="type_funding"
                                                key="type_funding"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag">
                                                            {record.request_guarantor &&
                                                            record
                                                                .request_guarantor
                                                                .guarantor_approved ===
                                                                "approved"
                                                                ? record.guarantor_cash_down !==
                                                                  null
                                                                    ? "Cash down payment"
                                                                    : "Admin funding"
                                                                : "Not yet determined"}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Date Applied"
                                                dataIndex="applied"
                                                key="applied"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text2 z-table-parag2">
                                                            {moment(
                                                                record.created_at
                                                            ).format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title="Amount"
                                                dataIndex="amount"
                                                key="amount"
                                                render={(text, record) => {
                                                    return (
                                                        <p className="z-table-text z-table-parag3">
                                                            {naira}{" "}
                                                            {formatCurrency(
                                                                record.amount ||
                                                                    record
                                                                        .loan_package
                                                                        .amount
                                                            )}
                                                        </p>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title=""
                                                dataIndex="action"
                                                key="Action"
                                                render={(i, record, index) => {
                                                    return (
                                                        <div className="">
                                                            <Button
                                                                className="z-tablebtn"
                                                                onClick={(
                                                                    e
                                                                ) => {
                                                                    this.showModal(
                                                                        e,
                                                                        index +
                                                                            (currentPage -
                                                                                1) *
                                                                                navDiff
                                                                    );
                                                                }}
                                                            >
                                                                {" "}
                                                                view
                                                            </Button>
                                                        </div>
                                                    );
                                                }}
                                            />
                                            <Column
                                                title=""
                                                dataIndex="payback"
                                                key="payback"
                                                render={(i, record, index) => {
                                                    if (
                                                        record.status ===
                                                        "approved"
                                                    ) {
                                                        return (
                                                            <div className="">
                                                                <Button
                                                                    className="z-tablebtn"
                                                                    onClick={(
                                                                        e
                                                                    ) =>
                                                                        this.displayPaybackSchedule(
                                                                            e,
                                                                            record.id
                                                                        )
                                                                    }
                                                                >
                                                                    Payback
                                                                    Schedules
                                                                </Button>
                                                            </div>
                                                        );
                                                    }
                                                    {
                                                        return "";
                                                    }
                                                }}
                                            />
                                        </Table>
                                    </div>
                                    {usersLoans.length > 0 && (
                                        <div className="z-admin-gr-div">
                                            <NavigationTab
                                                noOfPages={this.state.noOfPages}
                                                currentPage={
                                                    this.state.currentPage
                                                }
                                                navigateLeft={this.navigateLeft}
                                                navigateRight={
                                                    this.navigateRight
                                                }
                                            />
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        <Modal
                            className="z-custom z-custom2 "
                            visible={viewDetails}
                            title={"w"}
                            onOk={this.handleOk}
                            onCancel={(e) => {
                                this.closeModal(e);
                            }}
                            footer={[
                                usersLoans.length > 0 &&
                                usersLoans[currentIndex].status ===
                                    "pending" ? (
                                    <div
                                        className="z-flex-row"
                                        key="confirmLoan"
                                    >
                                        <Popconfirm
                                            title="Are you sure you want to decline the loan request?"
                                            onConfirm={(e) => {
                                                this.declineLoan(e);
                                            }}
                                            onCancel={(e) => {
                                                this.cancel();
                                            }}
                                            okText="Decline"
                                            cancelText="No"
                                            key="1"
                                        >
                                            <Button
                                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-flex-row"
                                                shape="round"
                                                size="large"
                                                key="3"
                                                loading={declineRequest}
                                            >
                                                Decline
                                                <Icon type="close" />
                                            </Button>
                                        </Popconfirm>
                                        ,
                                        <Popconfirm
                                            title="Are you sure you want to accept the loan request?"
                                            onConfirm={(e) => {
                                                this.acceptRequest(e);
                                            }}
                                            onCancel={(e) => {
                                                this.cancel();
                                            }}
                                            okText="Accept"
                                            cancelText="No"
                                            key="20"
                                        >
                                            <Button
                                                className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                                shape="round"
                                                size="large"
                                                key="23"
                                                loading={addRequest}
                                            >
                                                Accept
                                                <Icon type="check" />
                                            </Button>{" "}
                                        </Popconfirm>
                                    </div>
                                ) : usersLoans.length > 0 &&
                                  usersLoans[currentIndex].status ===
                                      "declined" ? (
                                    <p className="z-declined">Declined</p>
                                ) : usersLoans.length > 0 &&
                                  usersLoans[currentIndex].status ===
                                      "approved" &&
                                  usersLoans[currentIndex].payment_status !==
                                      "unpaid" ? (
                                    <p className="z-accepted">
                                        Accepted and Disbursed
                                    </p>
                                ) : usersLoans.length > 0 &&
                                  usersLoans[currentIndex].status ===
                                      "approved" &&
                                  usersLoans[currentIndex].loan_disbursed ===
                                      0 ? (
                                    <Popconfirm
                                        title="Are you sure you want to disburse into the user wallet?"
                                        onConfirm={(e) => {
                                            this.transferFund(e);
                                        }}
                                        onCancel={(e) => {
                                            this.cancel();
                                        }}
                                        okText="Disburse"
                                        cancelText="No"
                                        key="22"
                                    >
                                        <Button
                                            className="z-landing-btn z-section-btn z-profile-button z-skeleton-btn z-skeleton-bg"
                                            shape="round"
                                            size="large"
                                            key="21"
                                            loading={disburse}
                                        >
                                            Disburse Loan
                                            <Icon type="check" />
                                        </Button>{" "}
                                    </Popconfirm>
                                ) : (
                                    usersLoans.length > 0 &&
                                    usersLoans[currentIndex].status ===
                                        "cancelled" && (
                                        <p className="z-accepted">Cancelled</p>
                                    )
                                ),
                            ]}
                        >
                            <div>
                                <div className="z-flex-space z-nav-holder z-modal-nav">
                                    <div
                                        className={
                                            currentUser === "guarantor"
                                                ? "z-not-left  z-flex-column-center z-modal-b z-modal-buser"
                                                : "z-not-left  z-flex-column-center z-modal-b"
                                        }
                                        onClick={(e) => {
                                            this.setUser("user");
                                        }}
                                    >
                                        <Icon type="left" />
                                    </div>
                                    <div className="z-flex-space z-width-35 z-nav-holder-p z-muser">
                                        {currentUser === "user"
                                            ? "User Profile"
                                            : "Guarantor Profile"}
                                    </div>
                                    <div
                                        className={
                                            currentUser === "user"
                                                ? "z-not-left z-flex-column-center z-modal-b z-modal-buser"
                                                : "z-not-left z-flex-column-center z-modal-b"
                                        }
                                        onClick={(e) => {
                                            this.setUser("guarantor");
                                        }}
                                    >
                                        <Icon type="right" />
                                    </div>
                                </div>
                                <Row>
                                    <div className="z-flex-space z-p-3">
                                        <div className="z-testimonial-avatar z-profile-avatar z-mb-1 z-img-100">
                                            {currentUser === "user" && (
                                                <img
                                                    src={
                                                        usersLoans.length > 0 &&
                                                        (usersLoans[
                                                            currentIndex
                                                        ].user.user_profile &&
                                                        usersLoans[currentIndex]
                                                            .user.user_profile
                                                            .avatar
                                                            ? usersLoans[
                                                                  currentIndex
                                                              ].user
                                                                  .user_profile
                                                                  .avatar
                                                            : DefaultAvatar)
                                                    }
                                                    alt="avatar"
                                                />
                                            )}
                                            {currentUser === "guarantor" &&
                                            usersLoans.length > 0 &&
                                            usersLoans[currentIndex]
                                                .request_guarantor ? (
                                                <img
                                                    src={this.guarantorProfilePicture(
                                                        usersLoans,
                                                        currentIndex
                                                    )}
                                                    alt="avatar"
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className="z-width-64">
                                            {currentUser === "user" && (
                                                <div>
                                                    {usersLoans.length > 0 && (
                                                        <p className="z-modal-header">
                                                            {
                                                                usersLoans[
                                                                    currentIndex
                                                                ].user.firstname
                                                            }{" "}
                                                            {
                                                                usersLoans[
                                                                    currentIndex
                                                                ].user.lastname
                                                            }
                                                        </p>
                                                    )}
                                                </div>
                                            )}

                                            {currentUser === "guarantor" && (
                                                <div>
                                                    {usersLoans.length > 0 &&
                                                    usersLoans[currentIndex]
                                                        .request_guarantor &&
                                                    usersLoans[currentIndex]
                                                        .request_guarantor
                                                        .user ? (
                                                        <p className="z-modal-header">
                                                            {
                                                                usersLoans[
                                                                    currentIndex
                                                                ]
                                                                    .request_guarantor
                                                                    .user
                                                                    .firstname
                                                            }{" "}
                                                            {
                                                                usersLoans[
                                                                    currentIndex
                                                                ]
                                                                    .request_guarantor
                                                                    .user
                                                                    .lastname
                                                            }
                                                        </p>
                                                    ) : (
                                                        ""
                                                    )}
                                                </div>
                                            )}
                                            {currentUser === "user" && (
                                                <div className="z-width-100 z-ml-2 z-mt-8">
                                                    <div className="z-flex-start">
                                                        <div className="z-modal-left">
                                                            <p>Date applied </p>
                                                            <p>
                                                                Amount Requested
                                                            </p>
                                                            <p>
                                                                Current Monthly
                                                                Salary
                                                            </p>
                                                            <p>Current Level</p>
                                                            <p>Company</p>
                                                            <p>
                                                                Repayment Option
                                                            </p>
                                                            <p>
                                                                Repayment Option
                                                                Status
                                                            </p>
                                                        </div>
                                                        <div className="z-modal-right z-ml-8">
                                                            <p>
                                                                {usersLoans.length >
                                                                    0 &&
                                                                    moment(
                                                                        usersLoans[
                                                                            currentIndex
                                                                        ]
                                                                            .created_at
                                                                    ).format(
                                                                        "Do MMMM YYYY"
                                                                    )}
                                                            </p>
                                                            <p>
                                                                {naira}{" "}
                                                                {usersLoans.length >
                                                                    0 &&
                                                                    formatCurrency(
                                                                        usersLoans[
                                                                            currentIndex
                                                                        ]
                                                                            .amount ||
                                                                            usersLoans[
                                                                                currentIndex
                                                                            ]
                                                                                .loan_package
                                                                                .amount
                                                                    )}
                                                            </p>
                                                            <p>
                                                                {naira}{" "}
                                                                {usersLoans.length >
                                                                    0 &&
                                                                    formatCurrency(
                                                                        usersLoans[
                                                                            currentIndex
                                                                        ]
                                                                            .current_salary
                                                                    )}
                                                            </p>
                                                            <p>
                                                                {usersLoans.length >
                                                                    0 &&
                                                                    usersLoans[
                                                                        currentIndex
                                                                    ]
                                                                        .company_level &&
                                                                    usersLoans[
                                                                        currentIndex
                                                                    ]
                                                                        .company_level
                                                                        .name}
                                                            </p>
                                                            <p>
                                                                {usersLoans.length >
                                                                    0 &&
                                                                    usersLoans[
                                                                        currentIndex
                                                                    ].user
                                                                        .company
                                                                        .name}
                                                            </p>
                                                            <p>
                                                                {usersLoans.length >
                                                                    0 &&
                                                                    usersLoans[
                                                                        currentIndex
                                                                    ]
                                                                        .repayment_option}
                                                            </p>
                                                            <p>
                                                                {usersLoans.length >
                                                                    0 &&
                                                                usersLoans[
                                                                    currentIndex
                                                                ]
                                                                    .repayment_option ===
                                                                    REPAYMENT_OPTIONS.BANK
                                                                    ? this.getMandateStatus(
                                                                          usersLoans,
                                                                          currentIndex
                                                                      )
                                                                    : "Approved"}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}

                                            {currentUser === "guarantor" ? (
                                                usersLoans.length > 0 &&
                                                usersLoans[currentIndex]
                                                    .request_guarantor ? (
                                                    <div className="z-width-100 z-ml-2 z-mt-8">
                                                        <div className="z-flex-start">
                                                            <div className="z-modal-left">
                                                                <p>Email </p>
                                                                <p>
                                                                    Monthly
                                                                    Salary
                                                                </p>
                                                                <p>
                                                                    Approved
                                                                    Date
                                                                </p>
                                                                <p>Status</p>
                                                            </div>
                                                            <div className="z-modal-right z-ml-8">
                                                                <p>
                                                                    {usersLoans.length >
                                                                        0 &&
                                                                        usersLoans[
                                                                            currentIndex
                                                                        ]
                                                                            .request_guarantor &&
                                                                        usersLoans[
                                                                            currentIndex
                                                                        ]
                                                                            .request_guarantor
                                                                            .guarantor_email}
                                                                </p>
                                                                <p>
                                                                    {naira}{" "}
                                                                    {usersLoans.length >
                                                                        0 &&
                                                                        usersLoans[
                                                                            currentIndex
                                                                        ]
                                                                            .request_guarantor &&
                                                                        formatCurrency(
                                                                            usersLoans[
                                                                                currentIndex
                                                                            ]
                                                                                .request_guarantor
                                                                                .guarantor_salary
                                                                        )}
                                                                </p>
                                                                <p>
                                                                    {usersLoans.length >
                                                                        0 &&
                                                                    usersLoans[
                                                                        currentIndex
                                                                    ]
                                                                        .request_guarantor &&
                                                                    usersLoans[
                                                                        currentIndex
                                                                    ]
                                                                        .request_guarantor
                                                                        .guarantor_approved ===
                                                                        "pending"
                                                                        ? "Pending Approval"
                                                                        : usersLoans.length >
                                                                              0 &&
                                                                          usersLoans[
                                                                              currentIndex
                                                                          ]
                                                                              .request_guarantor &&
                                                                          usersLoans[
                                                                              currentIndex
                                                                          ]
                                                                              .request_guarantor
                                                                              .guarantor_approved &&
                                                                          moment(
                                                                              usersLoans[
                                                                                  currentIndex
                                                                              ]
                                                                                  .request_guarantor
                                                                                  .updated_at
                                                                          ).format(
                                                                              "Do MMMM YYYY"
                                                                          )}
                                                                </p>
                                                                <p>
                                                                    {usersLoans.length >
                                                                        0 &&
                                                                        usersLoans[
                                                                            currentIndex
                                                                        ]
                                                                            .request_guarantor &&
                                                                        usersLoans[
                                                                            currentIndex
                                                                        ]
                                                                            .request_guarantor
                                                                            .guarantor_approved}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    "No guarantor details found"
                                                )
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Modal>
                    </div>
                </Skeleton>
            </div>
        );
    }
}
