import { API_URL, API_URL_V2 } from "../constants";

export const BASE_URL = API_URL;
export const BASE_URL_V2 = API_URL_V2;

export const getUserUrl = `${BASE_URL}user`;
export const UserLoginUrl = `${BASE_URL}login`;
export const UserlogOutURL = `${BASE_URL}logout`;
export const registerUserUrl = `${BASE_URL}register`;
export const addIndustryUrl = `${BASE_URL}admin/industry/new`;
export const addCompanyUrl = `${BASE_URL}admin/company/new`;
export const addLoanPackageUrl = `${BASE_URL}admin/loan-package/new`;
export const getAllIndustryUrl = `${BASE_URL}industries`;
export const getAllCompanyUrl = `${BASE_URL}companies`;
export const getAllPackagesUrl = `${BASE_URL}loan-packages`;
export const getAllUserUrl = `${BASE_URL}admin/users`;
export const requestLoanUrl = `${BASE_URL}loan-request/new`;
export const updateUserUrl = `${BASE_URL}user/update-profile`;
export const getLoanRequestsUrl = `${BASE_URL}loan-requests`;
export const getGuarantorRequestsUrl = `${BASE_URL}guarantor-requests`;
export const getLoanRequestUrl = `${BASE_URL}admin/loan-requests`;
export const verifyEmailUrl = `${BASE_URL}email/verify`;
export const resendEmailVerificationLinkURL = `${BASE_URL}email/resend`;
export const guarantorBankDetailsUrl = `${BASE_URL}guarantor-request/loan-request/bankDetails`;
export const guarantorAcceptRequestUrl = `${BASE_URL}guarantor-request/approve/loan`;
export const guarantorDeclineRequestUrl = `${BASE_URL}guarantor-request/declined/loan`;
export const guarantorGuestDeclineRequestUrl = `${BASE_URL}guarantor-request/guest/declined/loan`;
export const guarantorGuestAcceptRequestUrl = `${BASE_URL}guarantor-request/guest/accept/loan`;
export const adminRequestResponseUrl = `${BASE_URL}admin/loan-request/change-status`;
export const getUserCardsUrl = `${BASE_URL}cards`;
export const getUserCardByIdUrl = (id) => `${BASE_URL}cards/${id}`;
export const saveCardUrl = `${BASE_URL}card-authorization`;
export const resetPasswordLinkUrl = `${BASE_URL}password/email`;
export const verifyPasswordLinkUrl = `${BASE_URL}password/reset`;
export const changeAdminRoleUrl = `${BASE_URL}user/change-role`;
export const addNewAdminUrl = `${BASE_URL}user/new-admin`;
export const approveCompanyUrl = (id) =>
    `${BASE_URL}admin/company/${id}/action`;
export const resolveAccountUrl = (params) =>
    `${BASE_URL}user/bank_details/resolve?account_number=${params.account}&bank_code=${params.bank}`;
export const getBanksUrl = `https://api.paystack.co/bank`;
export const initializeCardUrl = `${BASE_URL}cards/initialize`;
export const getWalletBalanceUrl = `${BASE_URL}wallet`;
export const transferMoneyToBankUrl = `${BASE_URL}wallet/transfer/bank`;
export const initializeWalletTrnsactionUrl = `${BASE_URL}wallet/fund/initialize`;
export const fundWalletUrl = `${BASE_URL}wallet/fund/confirm`;
export const cashDownUrl = `${BASE_URL}guarantor-request/loan-request/cash-down`;
export const fundCashDownUrl = `${BASE_URL}wallet/fund/pay`;
export const transferWithdrawalUrl = `${BASE_URL}wallet/transfer/bank`;
export const getNotificationsUrl = `${BASE_URL}notifications`;
export const getUnreadNotificationsUrl = `${BASE_URL}notifications/unread`;
export const disburseLoanUrl = `${BASE_URL}admin/loan-requests/credit-wallet`;
export const loanRepaymentProgressUrl = (loanRequestId) =>
    `${BASE_URL}loan-request/${loanRequestId}/payback-schedule`;
export const loanRepaymentSchedules = `${BASE_URL}loan-request/payback-schedules`;
export const deleteNotificationUrl = (notificationId) =>
    `${BASE_URL}notifications/${notificationId}`;
export const deleteAllNotificationUrl = `${BASE_URL}notifications`;
export const getAllTransactionUrl = `${BASE_URL}admin/transactions`;
export const getAllSystemTransactionUrl = `${BASE_URL}admin/system-transactions`;
export const getUserTransactionUrl = `${BASE_URL}admin/transactions/user`;
export const getUserWalletTransactionsURL = `${BASE_URL}wallet/transactions`;
export const markAllNotificationUrl = `${BASE_URL}notifications/read`;
export const approveLoanPackageUrl = (loanPackageId) =>
    `${BASE_URL}loan-package/${loanPackageId}/approval`;
export const loanPackageUpdateUrl = (loanPackageId) =>
    `${BASE_URL}admin/loan-package/${loanPackageId}`;
export const companyUpdateUrl = (companyId) =>
    `${BASE_URL}admin/company/${companyId}`;
export const payLoanScheduleUrl = (loanPaybackSchedule, guarantor) =>
    `${BASE_URL}loan-payback/${loanPaybackSchedule}/pay?guarantor=${guarantor}`;
export const bankURL = `${BASE_URL}bank`;
export const uploadFileURL = `${BASE_URL}file/upload`;
export const uploadAvatarURL = `${BASE_URL}user/avatar`;
export const getAllblacklistUrl = `${BASE_URL}admin/blacklist`;
export const createBlacklistUrl = `${BASE_URL}admin/blacklist`;
export const createBlacklistByUserIdUrl = (userId) =>
    `${BASE_URL}admin/blacklist/${userId}`;
export const deleteBlacklistUrl = (blacklistId) =>
    `${BASE_URL}admin/blacklist/${blacklistId}`;
export const getComanyLevelByCompanyIdUrl = (companyId) =>
    `${BASE_URL}admin/company-level/${companyId}/get-by-company-id`;
export const createCompanyLevelUrl = `${BASE_URL}admin/company-level`;
export const editCompanyLevelUrl = (companyLevelId) =>
    `${BASE_URL}admin/company-level/${companyLevelId}`;
export const deleteCompanyLevelUrl = (companyLevelId) =>
    `${BASE_URL}admin/company-level/${companyLevelId}`;
export const getCompanyUrl = (companyId) =>
    `${BASE_URL}admin/company/${companyId}`;
export const getAllCompanyLevelsUrl = `${BASE_URL}company-levels`;
export const cancelLoanRequestUrl = `${BASE_URL}loan-requests/cancel`;
export const deleteCompanyUrl = (companyId) =>
    `${BASE_URL}admin/company/${companyId}`;
export const deleteLoanPackageUrl = (loanPackageId) =>
    `${BASE_URL}admin/loan-package/${loanPackageId}`;
export const updateUserStatusUrl = (userdId) =>
    `${BASE_URL}admin/update-user-status/${userdId}`;
export const paybackSummaryUrl = `${BASE_URL}loan-request/payback-summary`;
export const getReferalUrl = `${BASE_URL}referal`;
export const redeemReferalUrl = `${BASE_URL}referal/redeem`;
export const convertReferalPointsUrl = `${BASE_URL}referal/convert`;
export const getAdminSettingsUrl = `${BASE_URL}admin/settings`;
export const updateAdminSettingsUrl = `${BASE_URL}admin/settings/update`;
export const markLoanScheduleAsPaidUrl = (loanScheduleId) =>
    `${BASE_URL}mark-loan-as-paid/${loanScheduleId}`;
export const userHasAccountUrl = `${BASE_URL}user-has-account`;
export const initializeCardV2Url = `${BASE_URL_V2}cards/initialize`;
export const authorizeCardV2Url = `${BASE_URL_V2}cards/authorization`;
export const getDirectDebitBanksUrl = `${BASE_URL}direct-debit/bank-codes`;
export const getDirectDebitNameEnquiryUrl = `${BASE_URL}direct-debit/name-enquiry`;
export const createMandateUrl = `${BASE_URL}direct-debit/mandate`;
export const getMandateUrl = (mandateId) => `${BASE_URL}direct-debit/mandate/${mandateId}`;
export const getMandatesUrl = `${BASE_URL}direct-debit/mandates`;
export const generateLoanRequestAuthorizationCodeUrl = `${BASE_URL}loan-request/generate-otp`;
